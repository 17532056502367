<template>
  <div class="list-wrapper">
    <div class="page-block"></div>
    <div class="floor-wrapper">
      <div class="header-wrapper">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>模型列表</el-breadcrumb-item>
        </el-breadcrumb> -->
        <div class="list-type">
          <el-radio-group v-model="searchForm.filter" @change="handleSearch">
            <el-radio label>全部</el-radio>
            <el-radio
              v-for="item in types"
              :label="item.id + ''"
              :key="item.id"
              >{{ item.TypeName }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="search-wrapper">
          <el-input
            placeholder="搜索小区名称"
            v-model="searchForm.searchKey"
            clearable
            class="search-input input-with-select"
          >
            <el-cascader
              v-model="searchForm.city"
              clearable
              :props="{ value: 'id', label: 'name', children: 'son' }"
              :show-all-levels="false"
              slot="prepend"
              placeholder="请选择"
              :options="options"
            ></el-cascader>
            <!-- <el-select v-model="searchForm.city" slot="prepend" placeholder="请选择">
              <el-option
                :label="item.label"
                v-for="item in options"
                :key="item.value"
                :value="item.value"
              ></el-option>
            </el-select> -->
            <div slot="append" class="search-btn" @click="handleSearch">
              <svg-icon icon-class="search"></svg-icon>
            </div>
            <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
          </el-input>
        </div>
      </div>
      <div class="list-type">
        <el-form>
          <!-- <el-form-item label="筛选:">
            <el-radio-group v-model="searchForm.filter" @change="handleSearch">
              <el-radio label>全部</el-radio>
              <el-radio v-for="item in types" :label="item.id + ''" :key="item.id">{{item.TypeName}}</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="排序:">
            <el-radio-group v-model="searchForm.sort" @change="handleSearch">
              <el-radio label>综合</el-radio>
              <el-radio label="give">热门</el-radio>
              <el-radio label="download">下载量</el-radio>
              <el-radio label="money">价格</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="list-content">
        <div
          class="list-item-box"
          v-for="(item, index) in list"
          :key="index"
          @click="linkToDetail(item)"
        >
          <div class="list-item">
            <div class="item-image">
              <img
                :src="$globalConfig.VUE_APP_IMAGE_URL + item.pic"
                alt
                oncontextmenu="return false"
              />
            </div>
            <div class="item-info">
              <div class="item-content">{{ item.name }}</div>
              <div class="item-footer">
                <div>
                  <span>
                    <svg-icon icon-class="view"></svg-icon>
                    {{ item.check }}
                  </span>
                  <span>
                    <svg-icon icon-class="download"></svg-icon>
                    {{ item.download }}
                  </span>
                </div>
                <span class="name">{{ item.creator }}</span>
                <!-- <span v-if="isVIP" @click.stop="getAuthorContact">
                <svg-icon icon-class="telephone"></svg-icon>联系作者
              </span>
              <span v-else>
                <svg-icon icon-class="telephone"></svg-icon>购买后联系
              </span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-wrapper">
        <el-pagination
          @current-change="handlePageChange"
          background
          :current-page.sync="searchForm.page"
          :page-size="searchForm.pagenum"
          layout="prev, pager, next, jumper"
          :total="total"
          hide-on-single-page
        ></el-pagination>
      </div>
      <el-dialog title="联系作者" :visible.sync="modalVisible" width="400px">
        <el-form label-width="100px" label-suffix=":">
          <el-form-item label="手机号码">
            <div
              v-clipboard:copy="String(authorContact.phone)"
              v-clipboard:success="handleCopy"
            >
              {{ authorContact.phone }}
            </div>
          </el-form-item>
          <el-form-item label="微信号">
            <div
              v-clipboard:copy="authorContact.wechat"
              v-clipboard:success="handleCopy"
            >
              {{ authorContact.wechat }}
            </div>
          </el-form-item>
          <el-form-item label="邮箱">
            <div
              v-clipboard:copy="authorContact.email"
              v-clipboard:success="handleCopy"
            >
              {{ authorContact.email }}
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { provinceAndCityDataPlus } from "element-china-area-data";
import { getModelList, getAuthoContact, getModelType, getCity } from "@/api";
export default {
  data() {
    return {
      options: provinceAndCityDataPlus,
      list: [],
      total: 0,
      searchForm: {
        searchKey: "",
        city: "",
        filter: "",
        sort: "",
        page: 1,
        pagenum: 10,
      },
      types: [],
      authorContact: {},
      modalVisible: false,
    };
  },
  created() {
    this.getCity();
    this.getTypes();
    this.getParams();
    this.getData();
    this.$store.dispatch("getMessage");
  },
  watch: {
    $route: {
      handler() {
        this.getParams();
        this.getData();
      },
    },
  },
  computed: {
    isVIP() {
      return (
        this.$store.getters.userinfo &&
        this.$store.getters.userinfo.vip !== "普通会员"
      );
    },
  },
  methods: {
    async getCity() {
      const res = await getCity({});
      console.log("res >>", res);
      this.options = res;
    },
    getParams() {
      const {
        searchKey = "",
        area = "",
        site = "",
        filter = "",
        sort = "",
        page = 1,
        pagenum = 10,
      } = this.$route.query;
      this.searchForm = {
        searchKey,
        city: [area ? parseInt(area) : "", site ? parseInt(site) : ""],
        filter,
        sort,
        page: parseInt(page),
        pagenum: parseInt(pagenum),
      };
    },
    linkToDetail(item) {
      this.$router.push({
        path: "/detail",
        query: {
          id: item.id,
        },
      });
    },
    handleSearch() {
      this.searchForm.page = 1;
      this.$router.push({
        path: "/list",
        query: {
          ...this.searchForm,
          area: this.searchForm.city[0],
          site: this.searchForm.city[1],
          city: undefined,
        },
      });
    },
    async getTypes() {
      const res = await getModelType({});
      this.types = res;
    },
    async getData() {
      const res = await getModelList({
        ...this.searchForm,
        area: this.searchForm.city[0],
        site: this.searchForm.city[1],
        city: undefined,
      });
      this.list = res.data;
      this.total = res.count;
    },
    handlePageChange(page) {
      this.searchForm.page = page;
      this.$router.push({
        path: "/list",
        query: {
          ...this.searchForm,
          area: this.searchForm.city[0],
          site: this.searchForm.city[1],
          city: undefined,
        },
      });
    },
    async getAuthorContact(id) {
      const res = await getAuthoContact({
        authorId: id,
      });
      this.authorContact = res.data;
      this.modalVisible = true;
    },
    handleCopy() {
      this.$message.success("复制成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-wrapper {
    .search-input {
      width: 460px;
      // margin-left: 35px;
      padding: 6px 0;
      border-radius: 4px;
      background-color: #fff;
      overflow: hidden;
      // border-bottom: 2px solid #e80000;
      ::v-deep {
        .el-input-group__prepend {
          background: transparent;
          border: none;
          position: relative;
          padding: 0;
          &::after {
            content: "";
            display: block;
            position: absolute;
            height: 60%;
            width: 1px;
            background: #bbb;
            right: 0;
            top: 20%;
          }
        }
        .el-input--suffix {
          width: 100px;
        }
        .el-input__inner {
          background: transparent;
          border: none;
          // color: #f0f0f0;
        }
        .el-input-group__append {
          background: transparent;
          border: none;
          color: #fff;
        }
        .search-btn {
          // width: 32px;
          padding: 10px 24px;
          margin: -9px -20px;
          font-size: 20px;
          cursor: pointer;
          color: #fff;
          background: #f80000;
          height: 48px;
        }
      }
    }
  }
}
.list-wrapper {
  .list-type {
    // padding: 20px 0;
    ::v-deep {
      .el-form-item__label {
        font-size: 16px;
      }
      .el-radio {
        margin-right: 20px;
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        font-size: 16px;
        padding-left: 0;
      }
    }
  }
  .list-content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .list-item-box {
    flex-basis: 20%;
    padding: 0 10px;
  }
  .list-item {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background: #fdfdfd;
    padding: 15px;
    cursor: pointer;
    // display: flex;
    margin-bottom: 20px;
    .item-image {
      // width: 350px;
      width: 100%;
      flex-shrink: 0;
      height: 220px;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        // object-fit: none;
        transition: all 0.3s ease;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    .item-info {
      // width: 0;
      // flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .item-content {
      width: 100%;
      font-size: 16px;
      color: #666;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      padding: 3px 0;
    }
    .item-footer {
      padding-top: 3px;
      // text-align: right;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #777;
      div span {
        margin-right: 15px;
      }
      span {
        display: inline-flex;
        align-items: center;
        // margin-left: 15px;
      }
      .name {
        display: inline-block;
        width: 0;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        text-align: right;
      }
      .svg-icon {
        margin-right: 3px;
      }
    }
  }
  .page-wrapper {
    text-align: center;
    // padding-top: 20px;
  }
}
.list-content {
  display: flex;
}
</style>
